dmx.bootbox = dmx.bootbox || {};

dmx.bootbox.convertOptions = function (options, type = 'alert') {
  const opts = {
    backdrop: 'static',
    body: options.message,
    center: options.centerVertical == true,
    fade: options.animate !== false,
    keyboard: options.onEscape !== false,
    scrollable: options.scrollable === true,
    showHeaderClose: options.closeButton !== false,
    size: options.size,
    swapButtonOrder: options.swapButtonOrder === true,
    title: options.title,
  };

  if (options.backdrop === true || options.backdrop === 'true') {
    opts.backdrop = true;
  } else if (options.backdrop === false || options.backdrop === 'false') {
    opts.backdrop = false;
  }

  if (options.buttons) {
    switch (type) {
      case 'alert':
        if (options.buttons.ok) {
          opts.closeButton = dmx.bootbox.convertButtonOptions(options.buttons.ok);
        }
        break;

      case 'confirm':
      case 'prompt':
        if (options.buttons.confirm) {
          opts.okButton = dmx.bootbox.convertButtonOptions(options.buttons.confirm);
        }
        if (options.buttons.cancel) {
          opts.closeButton = dmx.bootbox.convertButtonOptions(options.buttons.cancel);
        }
        break;
    }
  }

  if (type === 'prompt') {
    switch (options.inputType) {
      case 'select':
        opts.input = `
          <select class="form-select" ${options.multiple ? 'multiple' : ''} ${options.required ? 'required' : ''}>
            ${options.inputOptions.map(option => `
              <option value="${option.value || ''}" ${options.value == option.value ? 'selected' : ''}>
                ${option.text || option.value}
              </option>
            `).join('')}
          </select>
        `;
        break;

      case 'checkbox':
      case 'radio':
        opts.input = options.inputOptions.map((option, index) => `
          <div class="form-check">
            <input
              id="modbox-input-${index}"
              type="${options.inputType}"
              name="modbox-input-name"
              value="${option.value || ''}"
              ${options.value == option.value ? 'checked' : ''}
            >
            <label class="form-check-label" for="modbox-input-${index}">
              ${option.text || option.value}
            </label>
          </div>
        `).join('');
        break;

      case 'textarea':
        opts.input = `
          <textarea
            class="form-control"
            ${options.rows ? `rows="${options.rows}"` : ''}
            ${options.placeholder ? `placeholder="${options.placeholder}"` : ''}
            ${options.autocomplete ? `autocomplete="${options.autocomplete}"` : ''}
            ${options.minlength ? `minlength="${options.minlength}"` : ''}
            ${options.maxlength ? `maxlength="${options.maxlength}"` : ''}
            ${options.required ? 'required' : ''}
          >
            ${options.value || ''}
          </textarea>
        `;
        break;

      default:
        opts.input = `
          <input
            type="${options.inputType}"
            class="${options.inputType == 'range' ? 'form-range' : 'form-control'}"
            value="${options.value || ''}"
            ${options.placeholder ? `placeholder="${options.placeholder}"` : ''}
            ${options.autocomplete ? `autocomplete="${options.autocomplete}"` : ''}
            ${options.minlength ? `minlength="${options.minlength}"` : ''}
            ${options.maxlength ? `maxlength="${options.maxlength}"` : ''}
            ${options.min ? `min="${options.min}"` : ''}
            ${options.max ? `max="${options.max}"` : ''}
            ${options.step ? `step="${options.step}"` : ''}
            ${options.pattern ? `pattern="${options.pattern}"` : ''}
            ${options.required ? 'required' : ''}
          >
        `;
        break;
    }

    opts.body = `
      ${options.message ? `<p>${options.message}</p>` : ''}
      ${opts.input}
    `.trim();
  }

  return opts;
};

dmx.bootbox.convertButtonOptions = function (options) {
  const opts = {};
  if (options.label) opts.label = options.label;
  if (options.className) opts.style = options.className.slice(4);
  return opts;
};