dmx.Component('bootbox', {

  methods: {
    alert (options) {
      return modbox.alert(dmx.bootbox.convertOptions(options));
    },

    info (options) {
      return modbox.info(dmx.bootbox.convertOptions(options));
    },

    success (options) {
      return modbox.success(dmx.bootbox.convertOptions(options));
    },

    warning (options) {
      return modbox.warning(dmx.bootbox.convertOptions(options));
    },

    danger (options) {
      return modbox.danger(dmx.bootbox.convertOptions(options));
    },
  },

});
