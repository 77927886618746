dmx.Actions({

  'bootbox.alert': function (options) {
    return modbox.alert(dmx.bootbox.convertOptions(this.parse(options)));
  },

  'bootbox.info': function (options) {
    return modbox.info(dmx.bootbox.convertOptions(this.parse(options)));
  },

  'bootbox.success': function (options) {
    return modbox.success(dmx.bootbox.convertOptions(this.parse(options)));
  },

  'bootbox.warning': function (options) {
    return modbox.warning(dmx.bootbox.convertOptions(this.parse(options)));
  },

  'bootbox.danger': function (options) {
    return modbox.danger(dmx.bootbox.convertOptions(this.parse(options)));
  },

  'bootbox.confirm': function (options) {
    options = Object.assign({}, options);

    const condition = {
      'then': options.then,
      'else': options.else,
    };

    delete options.then;
    delete options.else;

    return modbox.confirm(dmx.bootbox.convertOptions(this.parse(options), 'confirm')).then(() => {
      if (condition.then) {
        return this._exec(condition.then).then(() => true);
      }

      return true;
    }).catch((err) => {
      if (err) console.error(err);
      
      if (condition.else) {
        return this._exec(condition.else).then(() => false);
      }

      return false;
    });
  },

  'bootbox.prompt': function (options) {
    const opts = dmx.extend(true, {
      okButton: {
        label: 'OK',
        style: 'primary',
      },
      closeButton: {
        label: 'Close',
        style: 'secondary',
      },
    }, dmx.bootbox.convertOptions(this.parse(options), 'prompt'), {
      destroyOnClose: true,
      defaultButton: false,
      button: [],
    });

    return new Promise(resolve => {
      const box = new modbox(opts);
      const inputEl = box.modalEl.querySelector('input,select,textarea');

      if (inputEl.type == 'radio') {
        inputEl.checked = true;
      }

      opts.okButton.close = false;
      opts.okButton.callback = () => {
        const isValid = inputEl ? inputEl.reportValidity() : true;

        if (isValid) {
          if (inputEl.nodeName == 'SELECT' && inputEl.multiple) {
            resolve(Array.from(inputEl.selectedOptions).map(option => option.value));
          } else if (inputEl.type == 'checkbox') {
            const checkedEl = box.modalEl.querySelectorAll('input:checked');
            resolve(Array.from(checkedEl).map(el => el.value));
          } else if (inputEl.type == 'radio') {
            const checkedEl = box.modalEl.querySelector('input:checked');
            resolve(checkedEl.value);
          } else {
            resolve(inputEl.value);
          }

          box.hide();
        }
      };

      const okBtn = box.addButton(opts.okButton);
      const closeBtn = box.addButton(opts.closeButton);

      if (inputEl && inputEl.nodeName == 'INPUT') {
        inputEl.addEventListener('keyup', event => {
          if (event.key == 'Enter') okBtn.click();
        });
      }

      box.addEvent('hide', () => resolve(null));
      box.show();
    });
  },

});
